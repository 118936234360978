export default [
  {
    name: 'pofTable',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'float' },
    ],
    keywords: [
      {
        keyword: 'columnPOFTimes',
        colHeaders: { USCustomary: ['POF Times'], Metric: ['POF Times'] },
      },
      {
        keyword: 'percentilePOFs',
        colHeaders: { USCustomary: ['POF (%)'], Metric: ['POF (%)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'numeric', numericFormat: { pattern: '0.00' } },
      ],
    },
    colWidths: [200, 200],
    height: 120,
  },
  {
    name: 'pofRiskTable',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'float' },
      { dataType: 'float' },
    ],
    keywords: [
      {
        keyword: 'columnPOFTimes',
        colHeaders: { USCustomary: ['POF Times'], Metric: ['POF Times'] },
      },
      {
        keyword: 'percentilePOFs',
        colHeaders: { USCustomary: ['POF (%)'], Metric: ['POF (%)'] },
      },
      {
        keyword: 'risks',
        colHeaders: { USCustomary: ['Risk'], Metric: ['Risk'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'numeric', numericFormat: { pattern: '0.00' } },
        { type: 'numeric', numericFormat: { pattern: '0' } },
      ],
    },
    colWidths: [200, 200, 240],
    height: 120,
  },
  {
    name: 'tableSoftEvidCrackingTable',
    keywords: [
      {
        keyword: 'rowNames1',
        colHeaders: {
          USCustomary: ['DM'],
          Metric: ['DM'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'tableSoftEvidCracking',
        colHeaders: {
          USCustomary: ['Probability (%)'],
          Metric: ['Probability (%)'],
          alignOptions: ['center'],
        },
        units: {
          USCustomary: '%',
          Metric: '%',
        },
        rowHeaders: false,
      },
    ],
    columnDisplayOptions: [
      { dataType: 'text' },
      { dataType: 'float' },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'DM-NAME'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        } else if(col === 1) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = '#.###'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        { type: 'numeric', numericFormat: { pattern: '0.00' } },
      ],
      colWidths: [200, 200],
      height: 200,
      minSpareRows: 0,
      maxRows: 14,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'tableSoftEvidThinningTable',
    keywords: [
      {
        keyword: 'rowNames2',
        colHeaders: {
          USCustomary: ['DM'],
          Metric: ['DM'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'tableSoftEvidThinning',
        colHeaders: {
          USCustomary: ['Probability (%)'],
          Metric: ['Probability (%)'],
          alignOptions: ['center'],
        },
        units: {
          USCustomary: '%',
          Metric: '%',
        },
        rowHeaders: false,
      },
    ],
    columnDisplayOptions: [
      { dataType: 'text' },
      { dataType: 'float' },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'DM-NAME'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        } else if(col === 1) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = '#.###'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        { type: 'numeric', numericFormat: { pattern: '0.00' } },
      ],
      colWidths: [200, 200],
      height: 200,
      minSpareRows: 0,
      maxRows: 14,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'inspectionHistory',
    keywords: [
      {
        keyword: 'inspectionTimes',
        colHeaders: {
          USCustomary: ['Time in Service (yr)'],
          Metric: ['Time in Service (yr)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
      {
        keyword: 'measuredThicknesses',
        colHeaders: {
          USCustomary: ['Measured Thickness (in)'],
          Metric: ['Measured Thickness (mm)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'measurementErrors',
        colHeaders: {
          USCustomary: ['Measurement Error Mean (in)'],
          Metric: ['Measurement Error Mean (mm)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'measurementErrorSDs',
        colHeaders: {
          USCustomary: ['Measurement Error SD (in)'],
          Metric: ['Measurement Error SD (mm)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        {type: 'numeric', numericFormat: { pattern: '0.0000' }},
        {type: 'numeric', numericFormat: { pattern: '0.0000' }},
        {type: 'numeric', numericFormat: { pattern: '0.0000' }},
        {type: 'numeric', numericFormat: { pattern: '0.0000' }},
      ],
      colWidths: [180, 200, 220, 220],
      height: 300,
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'tempHistory',
    keywords: [
      {
        keyword: 'tempTimeHistory',
        colHeaders: {
          USCustomary: ['Time in Service (yr)'],
          Metric: ['Time in Service (yr)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
      {
        keyword: 'tempHistory',
        colHeaders: {
          USCustomary: ['Temperature History (F)'],
          Metric: ['Temperature History (C)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['F'],
          Metric: ['C'],
        },
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        {type: 'numeric', numericFormat: { pattern: '0.0000' }},
        {type: 'numeric', numericFormat: { pattern: '0.0000' }},
      ],
      colWidths: [200, 240],
      height: 300,
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'sulfurConcentrationHistory',
    keywords: [
      {
        keyword: 'sulfurTimeHistory',
        colHeaders: {
          USCustomary: ['Time in Service (yr)'],
          Metric: ['Time in Service (yr)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
      {
        keyword: 'sulfurConcentrationHistory',
        colHeaders: {
          USCustomary: ['Sulfur Concentration History (wt%)'],
          Metric: ['Sulfur Concentration History (wt%)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        {type: 'numeric', numericFormat: { pattern: '0.0000' }},
        {type: 'numeric', numericFormat: { pattern: '0.0000' }},
      ],
      colWidths: [200, 280],
      height: 300,
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'crackingResultsTable',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'float' },
    ],
    keywords: [
      {
        keyword: 'crackingProb1Labels',
        colHeaders: { USCustomary: ['Damage Mechanism'], Metric: ['Damage Mechanism'] },
      },
      {
        keyword: 'crackingProb1ProbYes',
        colHeaders: { USCustomary: ['Probability (%)'], Metric: ['Probability (%)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'numeric' },
      ],
    },
    colWidths: [120, 120],
    height: 120,
  },
  {
    name: 'thiningResultsTable',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'float' },
    ],
    keywords: [
      {
        keyword: 'thinningProb1Labels',
        colHeaders: { USCustomary: ['Damage Mechanism'], Metric: ['Damage Mechanism'] },
      },
      {
        keyword: 'thinningProb1ProbYes',
        colHeaders: { USCustomary: ['Probability (%)'], Metric: ['Probability (%)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'numeric' },
      ],
    },
    colWidths: [120, 120],
    height: 120,
  },
  {
    name: 'corrosionRateBelief',
    keywords: [
      {
        keyword: 'corrosionRateBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'corrosionRateBeliefPrev',
    keywords: [
      {
        keyword: 'corrosionRateBeliefPrev',
      },
    ],
    type: 'output',
  },
  {
    name: 'corrosionRateBeliefCurr',
    keywords: [
      {
        keyword: 'corrosionRateBeliefCurr',
      },
    ],
    type: 'output',
  },
  {
    name: 'baseCorrosionRateBelief',
    keywords: [
      {
        keyword: 'baseCorrosionRateBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'damageFactorBelief',
    keywords: [
      {
        keyword: 'damageFactorBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'susceptibilityBelief',
    keywords: [
      {
        keyword: 'susceptibilityBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'cracksBelief',
    keywords: [
      {
        keyword: 'cracksBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureBelief',
    keywords: [
      {
        keyword: 'failureBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureBeliefPrev',
    keywords: [
      {
        keyword: 'failureBeliefPrev',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureBeliefCurr',
    keywords: [
      {
        keyword: 'failureBeliefCurr',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeBelief',
    keywords: [
      {
        keyword: 'failureTimeBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeBeliefPrev',
    keywords: [
      {
        keyword: 'failureTimeBeliefPrev',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeBeliefCurr',
    keywords: [
      {
        keyword: 'failureTimeBeliefCurr',
      },
    ],
    type: 'output',
  },
  {
    name: 'baseFailureTimeBelief',
    keywords: [
      {
        keyword: 'baseFailureTimeBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'baseFailureBelief',
    keywords: [
      {
        keyword: 'baseFailureBelief',
      },
    ],
    type: 'output',
  },
]
