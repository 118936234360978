export function plotBarResults(results, activeUnitSystem, previousJobFlag, inspectionsFlag) {
  let thicknessUnits = 'in'
  let damageRateUnits = 'mil/yr'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }
  if(activeUnitSystem === 'Metric') {
    damageRateUnits = 'mm/yr'
  }
  let corrosionRateStatesPlot
  let corrosionRateBeliefPlot
  let corrosionRateStatesPlotPrev
  let corrosionRateBeliefPlotPrev
  let corrosionRateStatesPlotCurr
  let corrosionRateBeliefPlotCurr
  let baseCorrosionRateStatesPlot
  let baseCorrosionRateBeliefPlot
  let damageFactorStatesPlot
  let damageFactorBeliefPlot
  let susceptibilityStatesPlot
  let susceptibilityBeliefPlot
  let cracksStatesPlot
  let cracksBeliefPlot
  let failureTimeStatesPlot
  let failureTimeBeliefPlot
  let baseFailureTimeStatesPlot
  let baseFailureTimeBeliefPlot
  let failureTimeStatesPlotPrev
  let failureTimeBeliefPlotPrev
  let failureTimeStatesPlotCurr
  let failureTimeBeliefPlotCurr
  let failureStatesPlot
  let failureBeliefPlot
  let failureStatesPlotPrev
  let failureBeliefPlotPrev
  let failureStatesPlotCurr
  let failureBeliefPlotCurr
  let baseFailureStatesPlot
  let baseFailureBeliefPlot
  const tracesProfileData1 = []
  const tracesProfileData2 = []
  const tracesProfileData3 = []
  const tracesProfileData4 = []
  const tracesProfileData5 = []
  const tracesProfileData6 = []
  const tracesProfileData7 = []
  const tracesProfileData8 = []
  const tracesProfileData9 = []
  const tracesProfileData10 = []
  const tracesProfileData11 = []
  const tracesProfileData12 = []
  const tracesProfileData13 = []
  const tracesProfileData14 = []
  const tracesProfileData15 = []
  const graphs = {}
  if(typeof results.corrosionRateStates !== 'undefined') {
    corrosionRateStatesPlot = results.corrosionRateStates
    corrosionRateBeliefPlot = results.dataTables.corrosionRateBelief.keywords[0].data
    tracesProfileData1.push({
      x: corrosionRateStatesPlot,
      y: corrosionRateBeliefPlot,
      type: 'bar',
    })
    graphs.Graph1 = {
      traces: tracesProfileData1,
      layout: {
        title: 'Final Corrosion Rate',
        xaxis: {
          title: `Corrosion Rate (${damageRateUnits})`,
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.corrosionRateStatesCurr !== 'undefined') {
    corrosionRateStatesPlotCurr = results.corrosionRateStatesCurr
    corrosionRateBeliefPlotCurr = results.dataTables.corrosionRateBeliefCurr.keywords[0].data
    tracesProfileData11.push({
      x: corrosionRateStatesPlotCurr,
      y: corrosionRateBeliefPlotCurr,
      type: 'bar',
    })
    graphs.Graph2 = {
      traces: tracesProfileData11,
      layout: {
        title: 'First Current Job Corrosion Rate',
        xaxis: {
          title: `Corrosion Rate (${damageRateUnits})`,
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.corrosionRateStatesPrev !== 'undefined') {
    corrosionRateStatesPlotPrev = results.corrosionRateStatesPrev
    corrosionRateBeliefPlotPrev = results.dataTables.corrosionRateBeliefPrev.keywords[0].data
    tracesProfileData10.push({
      x: corrosionRateStatesPlotPrev,
      y: corrosionRateBeliefPlotPrev,
      type: 'bar',
    })
    graphs.Graph3 = {
      traces: tracesProfileData10,
      layout: {
        title: 'Last Previous Job Corrosion Rate',
        xaxis: {
          title: `Corrosion Rate (${damageRateUnits})`,
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.baseCorrosionRateStates !== 'undefined' && (previousJobFlag === 'yes' || inspectionsFlag == 'yes')) {
    baseCorrosionRateStatesPlot = results.baseCorrosionRateStates
    baseCorrosionRateBeliefPlot = results.dataTables.baseCorrosionRateBelief.keywords[0].data
    tracesProfileData2.push({
      x: baseCorrosionRateStatesPlot,
      y: baseCorrosionRateBeliefPlot,
      type: 'bar',
    })
    graphs.Graph4 = {
      traces: tracesProfileData2,
      layout: {
        title: 'Base Corrosion Rate',
        xaxis: {
          title: `Corrosion Rate (${damageRateUnits})`,
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.damageFactorStates !== 'undefined') {
    damageFactorStatesPlot = results.damageFactorStates
    damageFactorBeliefPlot = results.dataTables.damageFactorBelief.keywords[0].data
    tracesProfileData3.push({
      x: damageFactorStatesPlot,
      y: damageFactorBeliefPlot,
      type: 'bar',
    })
    graphs.Graph5 = {
      traces: tracesProfileData3,
      layout: {
        title: 'damageFactor',
        xaxis: {
          title: 'Damage Factor',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.susceptibilityStates !== 'undefined') {
    susceptibilityStatesPlot = results.susceptibilityStates
    susceptibilityBeliefPlot = results.dataTables.susceptibilityBelief.keywords[0].data
    tracesProfileData4.push({
      x: susceptibilityStatesPlot,
      y: susceptibilityBeliefPlot,
      type: 'bar',
    })
    graphs.Graph6 = {
      traces: tracesProfileData4,
      layout: {
        title: 'Susceptibility',
        xaxis: {
          title: 'Susceptibility',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.cracksStates !== 'undefined') {
    cracksStatesPlot = results.cracksStates
    cracksBeliefPlot = results.dataTables.cracksBelief.keywords[0].data
    tracesProfileData5.push({
      x: cracksStatesPlot,
      y: cracksBeliefPlot,
      type: 'bar',
    })
    graphs.Graph7 = {
      traces: tracesProfileData5,
      layout: {
        title: 'Cracks',
        xaxis: {
          title: 'Cracks',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.failureTimeStates !== 'undefined') {
    failureTimeStatesPlot = results.failureTimeStates
    failureTimeBeliefPlot = results.dataTables.failureTimeBelief.keywords[0].data
    tracesProfileData6.push({
      x: failureTimeStatesPlot,
      y: failureTimeBeliefPlot,
      type: 'bar',
    })
    graphs.Graph8 = {
      traces: tracesProfileData6,
      layout: {
        title: 'Final Failure Time',
        xaxis: {
          title: 'Failure Time (yrs)',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.failureTimeStatesCurr !== 'undefined') {
    failureTimeStatesPlotCurr = results.failureTimeStatesCurr
    failureTimeBeliefPlotCurr = results.dataTables.failureTimeBeliefCurr.keywords[0].data
    tracesProfileData12.push({
      x: failureTimeStatesPlotCurr,
      y: failureTimeBeliefPlotCurr,
      type: 'bar',
    })
    graphs.Graph9 = {
      traces: tracesProfileData12,
      layout: {
        title: 'First Current Job Failure Time',
        xaxis: {
          title: 'Failure Time (yrs)',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.failureTimeStatesPrev !== 'undefined') {
    failureTimeStatesPlotPrev = results.failureTimeStatesPrev
    failureTimeBeliefPlotPrev = results.dataTables.failureTimeBeliefPrev.keywords[0].data
    tracesProfileData13.push({
      x: failureTimeStatesPlotPrev,
      y: failureTimeBeliefPlotPrev,
      type: 'bar',
    })
    graphs.Graph10 = {
      traces: tracesProfileData13,
      layout: {
        title: 'Last Previous Job Failure Time',
        xaxis: {
          title: 'Failure Time (yrs)',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.baseFailureTimeStates !== 'undefined' && (previousJobFlag === 'yes' || inspectionsFlag == 'yes')) {
    baseFailureTimeStatesPlot = results.baseFailureTimeStates
    baseFailureTimeBeliefPlot = results.dataTables.baseFailureTimeBelief.keywords[0].data
    tracesProfileData8.push({
      x: baseFailureTimeStatesPlot,
      y: baseFailureTimeBeliefPlot,
      type: 'bar',
    })
    graphs.Graph11 = {
      traces: tracesProfileData8,
      layout: {
        title: 'Base Failure Time',
        xaxis: {
          title: 'Failure Time (yrs)',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.failureStates !== 'undefined') {
    failureStatesPlot = results.failureStates
    failureBeliefPlot = results.dataTables.failureBelief.keywords[0].data
    tracesProfileData7.push({
      x: failureStatesPlot,
      y: failureBeliefPlot,
      type: 'bar',
    })
    graphs.Graph12 = {
      traces: tracesProfileData7,
      layout: {
        title: 'Final Probability of Failure',
        xaxis: {
          title: 'Failure',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.failureStatesCurr !== 'undefined') {
    failureStatesPlotCurr = results.failureStatesCurr
    failureBeliefPlotCurr = results.dataTables.failureBeliefCurr.keywords[0].data
    tracesProfileData14.push({
      x: failureStatesPlotCurr,
      y: failureBeliefPlotCurr,
      type: 'bar',
    })
    graphs.Graph13 = {
      traces: tracesProfileData14,
      layout: {
        title: 'First Current Job Probability of Failure',
        xaxis: {
          title: 'Failure',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.failureStatesPrev !== 'undefined') {
    failureStatesPlotPrev = results.failureStatesPrev
    failureBeliefPlotPrev = results.dataTables.failureBeliefPrev.keywords[0].data
    tracesProfileData15.push({
      x: failureStatesPlotPrev,
      y: failureBeliefPlotPrev,
      type: 'bar',
    })
    graphs.Graph14 = {
      traces: tracesProfileData15,
      layout: {
        title: 'Last Previous Job Probability of Failure',
        xaxis: {
          title: 'Failure',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  if(typeof results.baseFailureStates !== 'undefined' && (previousJobFlag === 'yes' || inspectionsFlag == 'yes')) {
    baseFailureStatesPlot = results.baseFailureStates
    baseFailureBeliefPlot = results.dataTables.baseFailureBelief.keywords[0].data
    tracesProfileData9.push({
      x: baseFailureStatesPlot,
      y: baseFailureBeliefPlot,
      type: 'bar',
    })
    graphs.Graph15 = {
      traces: tracesProfileData9,
      layout: {
        title: 'Base Probability of Failure',
        xaxis: {
          title: 'Failure',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  return graphs
}
